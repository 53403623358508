import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import {
  BusinessCard,
  Card,
  GetInTouch,
  Image,
  Layout,
  LinkButton,
  MediaObjectGrid,
  Paragraph,
  SEO,
  SocialMedia,
  Timeline,
} from "../components"
import { LayoutMainContainer } from "../components/Layout"
import { Colors } from "../utils/constants"

const IndexPage = ({ data }) => {
  const socialMedias = data.socialMedias.nodes[0]
  const getInTouch = data.getInTouch.nodes[0]
  const projectInPage = data.projectInPage.nodes[0]
  const postInPage = {
    topPost: data.postInPage.nodes[1],
    technology: data.postInPage.nodes[0],
    mindsetPost: data.postInPage.nodes[2],
  }
  const mediaGridInPage = {
    solutions: data.mediaGridInPage.nodes[0],
    link: data.mediaGridInPage.nodes[1].link,
    timeLine: {
      title: data.mediaGridInPage.nodes[1].title,
      content: data.mediaGridInPage.nodes[1].content.content,
      points: data.mediaGridInPage.nodes[1].mediaObject.map(point => ({
        title: point.title,
        content: point.content.content,
        icon: point.icon.file.url,
        hoverIcon: point.iconOnHover.file.url,
      })),
    },
  }
  const cardsInPage = {
    apply: data.cardsInPage.nodes[2],
    faq: data.cardsInPage.nodes[1],
    contact: data.cardsInPage.nodes[0],
  }
  const clientInPage = {
    clientsTopPage: data.clientInPage.nodes[0],
    otherClients: data.clientInPage.nodes[1],
  }
  const meetingCards = getInTouch.meetingCards.map(
    ({ content, icon, link, title }) => ({
      icon: icon.file.url,
      title: title,
      titleColor: Colors.RED_2,
      description: content.content,
      link: { label: link.title, href: link.url, theme: "red" },
      backgroundColor: Colors.PINK,
    })
  )
  const locations = getInTouch.location.map(
    ({ addressLine1, addressLine2, country, phone, postalCode }) => ({
      country: country,
      address1: addressLine1,
      address2: addressLine2,
      zip: postalCode,
      phone: phone,
    })
  )

  const [showFullTimeLine, setShowFulltimeLine] = React.useState(false)
  const [showFullProjects, setShowFullProjects] = React.useState(false)

  return (
    <Layout
      mainRenderer={children => (
        <StyledMainContent
          backgroundImage={
            postInPage.topPost.thumbnail.localFile?.childImageSharp
          }
        >
          {children}
        </StyledMainContent>
      )}
    >
      <SEO title="Home" />
      {/* landing section */}
      <StyledPost fluid>
        <StyledLandingContainer>
          <Row>
            <Col col="12" md="8">
              <Paragraph
                title={postInPage.topPost.title}
                content={postInPage.topPost.content.content}
                link={{
                  href: postInPage.topPost.link.url,
                  label: postInPage.topPost.link.content,
                }}
              />
            </Col>
          </Row>
          <StyledRow top="91" topMobile="275">
            <StyledClientLogosContainer>
              {clientInPage.clientsTopPage.client.map(
                ({ name, thumbnail, slug }) => (
                  <StyledClientLogoCol col="4" md="2" key={`top-${slug}`}>
                    <Image
                      fixedSources={thumbnail.localFile?.childImageSharp}
                      alt={name}
                    />
                  </StyledClientLogoCol>
                )
              )}
            </StyledClientLogosContainer>
          </StyledRow>
        </StyledLandingContainer>
        {/* software solutions section */}
        <StyledContainerWithMarginTop>
          <Row>
            <MediaObjectGrid
              mediaObjects={mediaGridInPage.solutions.mediaObject.map(
                ({ title, content, icon }) => ({
                  titleColor: Colors.GREY_5,
                  icon: icon.file.url,
                  title: title,
                  description: content.content,
                })
              )}
              paragraph={{
                title: mediaGridInPage.solutions.title,
                content: mediaGridInPage.solutions.content.content,
              }}
              isDescriptionInGrid
              col="2"
            />
          </Row>
        </StyledContainerWithMarginTop>
      </StyledPost>
      {/* success stories */}
      <StyledContainerWithMarginTop reduced id="success">
        <Row>
          <StyledCol top col="4" hiddenMdDown>
            <Row>
              {projectInPage.project
                .slice(0, 2)
                .map(({ title, description, thumbnail, slug }) => (
                  <StyledCol bottom col="12" key={slug}>
                    <Card
                      description={description.description}
                      isVertical
                      title={title}
                      isChildrenOnBottomEdge
                      titleColor={Colors.BLACK}
                      isCentred
                      height="100%"
                    >
                      <Image
                        fixedSources={thumbnail.localFile?.childImageSharp}
                        alt="project thumbnail"
                      />
                    </Card>
                  </StyledCol>
                ))}
            </Row>
          </StyledCol>
          <Col md="8" col="12">
            <Row>
              <Col col="12">
                <Paragraph
                  title={projectInPage.title}
                  content={projectInPage.content.content}
                />
              </Col>
            </Row>
            <Row>
              {projectInPage.project.map(
                ({ title, description, thumbnail, slug }, index) => (
                  <Col
                    col="12"
                    md="6"
                    key={slug}
                    hiddenMdUp={index < 2}
                    hiddenMdDown={!showFullProjects && index > 1}
                  >
                    <Card
                      description={description.description}
                      title={title}
                      isVertical
                      isChildrenOnBottomEdge
                      titleColor={Colors.BLACK}
                      isCentred
                      height="100%"
                    >
                      <Image
                        fixedSources={thumbnail.localFile?.childImageSharp}
                        alt="project thumbnail"
                      />
                    </Card>
                  </Col>
                )
              )}
            </Row>
            <StyledRow
              top="60" //TOOD: move it to styled component instead
              position="center"
              textAlign="center"
              alignItems="center"
            >
              <Col col="5" hiddenMdDown>
                <LinkButton uppercase href={projectInPage.link.url} theme="red">
                  {projectInPage.link.content}
                </LinkButton>
              </Col>
              <Col col="5" hiddenMdUp>
                <LinkButton
                  onClick={e => {
                    e.preventDefault()
                    setShowFullProjects(!showFullProjects)
                  }}
                  href="#success"
                  uppercase
                  theme="inverted"
                  stretched
                >
                  {showFullProjects ? "Show Less" : "Show More"}
                </LinkButton>
              </Col>
              <Col col="2" hiddenMdUp>
                <span style={{ fontWeight: "bold" }}>OR</span>
              </Col>
              <Col col="5" hiddenMdUp>
                <LinkButton
                  uppercase
                  href={projectInPage.link.url}
                  theme="red"
                  stretched
                >
                  {projectInPage.link.content}
                </LinkButton>
              </Col>
            </StyledRow>
          </Col>
        </Row>
      </StyledContainerWithMarginTop>
      {/* how we work section */}
      <StyledContainerWithMarginTop>
        <Row>
          <Col col="12">
            <Paragraph
              title={mediaGridInPage.timeLine.title}
              content={mediaGridInPage.timeLine.content}
            />
          </Col>
          <StyledTimeline col="12">
            <Timeline
              pointsInTime={mediaGridInPage.timeLine.points}
              fullTimeLine={showFullTimeLine}
            />
          </StyledTimeline>
        </Row>
        <Row>
          <Col col="12" hiddenMdDown>
            <LinkButton uppercase href="/contact" theme="red">
              Get In Touch
            </LinkButton>
          </Col>
          <Col col="6" hiddenMdUp>
            <LinkButton
              uppercase
              href="#"
              theme="inverted"
              stretched
              onClick={e => {
                e.preventDefault()
                setShowFulltimeLine(!showFullTimeLine)
              }}
            >
              {showFullTimeLine ? "Load Less" : "Load More"}
            </LinkButton>
          </Col>
          <Col col="6" hiddenMdUp>
            <LinkButton uppercase href="/projects" theme="red" stretched>
              Get In Touch.
            </LinkButton>
          </Col>
        </Row>
      </StyledContainerWithMarginTop>
      {/* our technologies section */}
      <StyledContainerWithMarginTop>
        <Paragraph
          title={postInPage.technology.title}
          content={postInPage.technology.content.content}
          link={{
            href: postInPage.technology.link.url,
            label: postInPage.technology.link.content,
          }}
          fixedSources={
            postInPage.technology.thumbnail.localFile.childImageSharp
          }
          mobile={{ isReversed: true }}
        />
      </StyledContainerWithMarginTop>
      {/* busniess mindset section */}
      <StyledContainerWithMarginTop>
        <Row justifyContent="center">
          <Col sm="12" lg="6">
            <Paragraph
              title={postInPage.mindsetPost.title}
              content={postInPage.mindsetPost.content.content}
              link={{
                href: postInPage.mindsetPost.link.url,
                label: postInPage.mindsetPost.link.content,
              }}
              centerH
            />
          </Col>
        </Row>
      </StyledContainerWithMarginTop>
      {/* best people section */}
      {data.contentBlockInPage?.nodes?.length ? (
        <StyledContainerWithMarginTop>
          <Row>
            <Col col="12" lg="6">
              <Paragraph title={data.contentBlockInPage.nodes[0].title} />
            </Col>
          </Row>
          {data.contentBlockInPage.nodes[0].post.map(
            ({ title, subtitle, content, thumbnail, link, slug }, index) => (
              <StyledRow top="80" key={slug}>
                <Col col="12">
                  <BusinessCard
                    title={title}
                    position={subtitle}
                    content={content.content}
                    img={thumbnail.localFile?.childImageSharp.fluid}
                    socialMedia={{ link: link.url, icon: link.icon.file.url }}
                    isReversed={index % 2 !== 0}
                  />
                </Col>
              </StyledRow>
            )
          )}
        </StyledContainerWithMarginTop>
      ) : null}
      {/* 3 cards */}
      <StyledContainerWithMarginTop reduced>
        <StyledCard>
          <Col col="12" lg="8">
            <Card
              description={cardsInPage.contact.content.content}
              title={cardsInPage.contact.title}
              childrenPosition="flex-end"
              backgroundColor={Colors.RED}
              titleColor={Colors.WHITE}
              textColor={Colors.WHITE}
            >
              <LinkButton
                uppercase
                href={cardsInPage.contact.link.url}
                theme="white_inverted"
              >
                {cardsInPage.contact.link.content}
              </LinkButton>
            </Card>
            <Card
              description={cardsInPage.faq.content.content}
              title={cardsInPage.faq.title}
              childrenPosition="flex-end"
              backgroundColor={Colors.GREY_10}
              titleColor={Colors.RED_2}
            >
              <LinkButton uppercase href={cardsInPage.faq.link.url} theme="red">
                {cardsInPage.faq.link.content}
              </LinkButton>
            </Card>
          </Col>
          <Col col="12" lg="4">
            <Card
              description={cardsInPage.apply.content.content}
              title={cardsInPage.apply.title}
              titleColor={Colors.RED_2}
              childrenPosition="flex-end"
              isVertical
            >
              <LinkButton
                uppercase
                href={cardsInPage.apply.link.url}
                theme="red"
              >
                {cardsInPage.apply.link.content}
              </LinkButton>
            </Card>
          </Col>
        </StyledCard>
      </StyledContainerWithMarginTop>
      {/* clients section */}
      <StyledClientContainer>
        <Row justifyContent="center">
          <Col col="6">
            <Paragraph title={clientInPage.otherClients.title} centerH />
          </Col>
        </Row>
        {clientInPage.otherClients.client && (
          <StyledClientLogoCol style={{ padding: 20 }}>
            <Row justifyContent="center" alignItems="center">
              {clientInPage.otherClients.client.map(
                ({ name, thumbnail, slug }) => (
                  <Col col="2" md="2" key={slug}>
                    {thumbnail?.localFile?.childImageSharp && (
                      <ImageWithHorizontalMargins
                        fixedSources={thumbnail.localFile?.childImageSharp}
                        alt={name || "logo"}
                      />
                    )}
                  </Col>
                )
              )}
            </Row>
          </StyledClientLogoCol>
        )}
        <Row justifyContent="center">
          <LinkButton
            uppercase
            href={clientInPage.otherClients.link.url}
            theme="red"
          >
            {clientInPage.otherClients.link.content}
          </LinkButton>
        </Row>
      </StyledClientContainer>
      {/* TODO: Add missing carousel for client testymony */}
      {/* have a project section */}
      <StyledContainerWithMarginTop>
        <GetInTouch
          title={getInTouch.title}
          content={getInTouch.content.content}
          meetingCards={meetingCards}
          locations={locations}
        />
      </StyledContainerWithMarginTop>
      {/* social media section */}
      <SocialMedia socialMedias={socialMedias} />
    </Layout>
  )
}

const StyledMainContent = styled(LayoutMainContainer)`
  background-image: url(${props => props.backgroundImage?.mobileImage?.src});
  background-repeat: no-repeat;
  background-position: top 150px right -127px;
  ${media.md` 
    background-image: url(${props => props.backgroundImage?.tabletImage?.src});
    background-position: top -30px right -140px;
  `}
  ${media.lg` 
    background-image: url(${props => props.backgroundImage?.desktopImage?.src});
    background-position: top 0 right -300px;
  `}
`

const ImageWithHorizontalMargins = styled(Image)`
  margin: 0 10px;
  width: 150px;
`

const StyledCol = styled(Col)`
  margin-top: ${props => (props.top ? "93px" : "auto")};
  margin-bottom: ${props => (props.bottom ? "10px" : "auto")};
`

const StyledRow = styled(Row)`
  margin-top: ${props =>
    props.topMobile ? props.topMobile + "px" : props.top + "px" || "auto"};
  justify-content: ${props => props.position || "flex-left"};
  & > div {
    text-align: ${props => props.textAlign || "auto"};
  }
  ${media.md` 
    margin-top: ${props => props.top + "px" || "auto"};
  `}
`

const StyledContainerWithMarginTop = styled(Container)`
  margin-top: 62px;
  div:last-of-type > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  ${media.lg`
    margin-top: ${props => (props.reduced ? "143px" : "230px")};
  `}
`
const StyledClientContainer = styled(Container)`
  ${media.xs`
    display: none`}
  padding : 40px 15px;
  ${media.lg`
    margin-top: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    div:nth-of-type(2) {
      margin: 40px 0px;
    }
    div:nth-of-type(2) > div {
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
  `}
`
const StyledTimeline = styled(Col)`
  ${media.md`
      margin-bottom: 60px;
    `}
`

const StyledCard = styled(Row)`
  & a {
    border-radius: 10000px;
  }
  & > div > div {
    margin-bottom: 20px;
    div:last-of-type {
      justify-content: flex-start;
      ${media.md`justify-content: flex-end;`}
    }
  }
  & > div:last-of-type > div {
    height: 100%;
  }
  ${media.lg`
    & > div > div:first-of-type {
      margin-bottom: 10px;
    }
    & > div > div {
      margin-bottom: 0px;
    }
  `}
`
const StyledLandingContainer = styled(Container)`
  position: relative;
  padding-top: 40px;
  ${media.lg`
  padding-top : 171px
`};
`
const StyledPost = styled(Container)`
  position: relative;
  overflow: hidden;
`
const StyledClientLogosContainer = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .gatsby-image-wrapper {
    max-width: 150px;
  }
`
const StyledClientLogoCol = styled(Col)`
  margin-bottom: 10px;
`

export default IndexPage

export const postQuery = graphql`
  query indexQuery(
    $page: ContentfulPageFilterListInput = {
      elemMatch: { title: { eq: "Home" } }
    }
  ) {
    postInPage: allContentfulPost(
      filter: { page: $page }
      sort: { fields: order }
    ) {
      nodes {
        ...post
        thumbnail {
          localFile {
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              mobileImage: fixed(toFormat: WEBP, width: 350, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              tabletImage: fixed(toFormat: WEBP, width: 400, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 900, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }

    clientInPage: allContentfulClientBlock(
      filter: { page: $page }
      sort: { fields: order }
    ) {
      nodes {
        ...clientBlock
        client {
          thumbnail {
            localFile {
              childImageSharp {
                fluid(toFormat: WEBP, maxHeight: 115, quality: 100) {
                  ...imageSharpFluid
                }
                mobileImage: fixed(toFormat: WEBP, width: 60, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
                tabletImage: fixed(toFormat: WEBP, width: 75, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
                desktopImage: fixed(toFormat: WEBP, width: 100, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
            file {
              url
            }
          }
        }
      }
    }

    projectInPage: allContentfulProjectBlock(limit: 4) {
      nodes {
        ...projectBlock
        project {
          ...project
          thumbnail {
            localFile {
              childImageSharp {
                mobileImage: fixed(toFormat: WEBP, width: 300, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
                tabletImage: fixed(toFormat: WEBP, width: 160, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
                desktopImage: fixed(toFormat: WEBP, width: 310, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }

    mediaGridInPage: allContentfulMediaObjectGrid(
      filter: { page: $page }
      sort: { fields: order }
    ) {
      nodes {
        ...mediaObjectGrid
      }
    }

    contentBlockInPage: allContentfulPostBlock(filter: { page: $page }) {
      nodes {
        ...contentBlock
        post {
          ...post
          thumbnail {
            localFile {
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 255, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }

    cardsInPage: allContentfulCard(
      filter: { page: $page }
      sort: { fields: order }
    ) {
      nodes {
        ...card
      }
    }
    reviewInPage: allContentfulReviewBlock(filter: { page: $page }) {
      nodes {
        ...reviewBlock
      }
    }
    getInTouch: allContentfulMeetingBlock(filter: { page: $page }, limit: 1) {
      nodes {
        ...meetingBlock
      }
    }

    socialMedias: allContentfulSocialMediaGroup(
      filter: { page: $page }
      limit: 1
    ) {
      nodes {
        ...socialMedia
      }
    }
  }
`
